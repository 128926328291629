///include /assets/js/app/p/checkout-info.js
class SCheckoutInfo extends CheckoutInfo {
    constructor() {
        super()
        this.payment = null
        this.usesProformaInvoices = null
    }
    get networkPropertyHandlers() {
        return Object.assign(
            {},
            super.networkPropertyHandlers,
            {
                payment: () => $.ajax(`/n/payment/config`),
                usesProformaInvoices: async () => (await this.preloadSingle("payment")).usesProformaInvoices,
            }
        )
    }
}
CheckoutInfo.register(SCheckoutInfo)
